.kZzUaJ {
    display: flex;
    flex-direction: column;
    flex: 0 0 240px;
    margin-right: 8px;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 2px 0px;
}
.gyRROG {
    width: 100%;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    align-self: flex-start;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 0px 0px;
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
}

.bosxIG {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    box-shadow: rgb(0 0 0 / 12%) 0px -1px 0px 0px inset;
    background-color: rgb(255, 255, 255);
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    min-height: 94px;
}
.jGYAMA {
    display: flex;
    flex: 0 0 32px;
    position: relative;
    padding: 8px 0px;
    width: 100%;
    margin-bottom: 4px;
}
.toggle-group.full-width {
    display: -webkit-flex;
    display: flex;
    width: 100%;
}
.toggle-group {
    position: absolute;
    right: 0;
    top: 0;
}
.toggle-group .btn.btn-primary.active {
    color: #333;
    background: #fff;
    border-bottom: none;
}
[data-toggle=buttons]>.btn-group>.btn input[type=checkbox], [data-toggle=buttons]>.btn-group>.btn input[type=radio], [data-toggle=buttons]>.btn input[type=checkbox], [data-toggle=buttons]>.btn input[type=radio] {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;
}
.toggle-group .btn.btn-primary.active:after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 4px;
    background-color: #55bd6c;
    left: 0;
    right: 0;
}
.jVAPDn {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
    margin-bottom: 4px;
    margin-top: 12px;
    color: rgb(180, 184, 186);
    position: relative;
    padding: 0px 12px;
}
.dmCvyP {
    position: relative;
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.dmCvyP .mdi-pre {
    position: absolute;
    left: 4px;
    font-size: 14px;
    background: transparent;
}
.eZAncJ {
    border-radius: 4px;
    overflow: hidden;
    font-weight: 600;
    padding: 2px 8px 2px 20px;
    width: 100%;
    font-size: 12px;
    line-height: 1.25;
    letter-spacing: 0.2px;
    border: 1px solid rgba(34, 35, 38, 0.12);
    color: rgb(34, 35, 38);
    transition: all 0.4s ease 0s;
    background-color: rgb(255, 255, 255);
}
.list-sort {
    display: table;
    width: 100%;
}
.list-sort .list-sort-item {
    cursor: pointer;
    font-size: 10px;
    color: #9b9b9b;
    font-weight: 600;
    display: table-cell;
    padding: 2px 8px 2px 16px;
}
.list-sort .list-sort-item.active {
    color: #23ac50;
    position: relative;
}
.iQVKWN {
    overflow-y: auto;
}
.ticker-item {
    display: -webkit-flex;
    display: flex;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom: 1px solid hsla(0,0%,59.2%,.2);
    padding: 4px 12px;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-grow: 0;
    flex-grow: 0;
    background-color: #fff;
}
.ticker-item .currency-logo {
    margin-right: 8px;
    font-size: 8px;
}
.ticker-item .market, .ticker-item .market-name {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
}
.ticker-item .market {
    -webkit-flex-grow: 1;
    flex-grow: 1;
}
.ticker-item .market-name {
    color: #1c1b21;
    font-weight: 700;
}
.ticker-item .market, .ticker-item .market-name {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
}
.ticker-item .market-name-text {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: .4px;
    color: #1c1b21;
}
.ticker-item .market-name-text .subtext {
    color: #92989b;
    font-size: 10px;
    line-height: 1.2;
    text-align: right;
    text-transform: uppercase;
    font-weight: 700;
}
.ticker-item .market-change, .ticker-item .price {
    display: -webkit-flex;
    display: flex;
}
.cFmqCk {
    display: block;
    color: rgb(0, 200, 83);
    font-size: 10px;
    line-height: 1.2;
    text-align: right;
    font-weight: bold;
}
.jsJuLQ {
    display: block;
    color: rgb(244, 67, 54);
    font-size: 10px;
    line-height: 1.2;
    text-align: right;
    font-weight: bold;
}
.ticker-item .price {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: flex-end;
    align-items: flex-end;
    color: #485156;
    z-index: 1;
}
.ticker-item .price-box {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}
.ticker-item .price-text {
    font-weight: 700;
    color: #222326;
    text-align: right;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: .4px;
}