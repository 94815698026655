.s-layout {
  /* display: flex; */
  /* width: 100%; */
  /* min-height: 100vh; */
  margin-top: 50px;
}

.s-layout__content {
  flex: 1 1;
}

/* Sidebar */
.s-sidebar__trigger {
  z-index: 2;
  position: fixed;
  top: 69px;
  left: 0;
  width: 100%;
  height: 3.3em;
  background: #f7f7f7;
}


.s-sidebar__trigger > span {
  display: inline-block;
  margin: 0.9em 0 0 1.5em;
  color: var(--main-color);
}

.s-sidebar__nav {
  z-index: 2;
  /* font-family: "Open Sans", sans-serif; */
  position: fixed;
  top: 3.8rem;
  left: -20em;
  overflow: hidden;
  transition: all 0.3s ease-in;
  width: 20em;
  height: 100vh;
  font-weight: 500;
  /* border-right: 1px solid rgba(29, 29, 29, 0.08); */
  overflow-x: hidden;
  overflow-y: auto;
  padding: 40px 16px 0px;
  background: rgb(249, 249, 249);
  box-shadow: rgba(29, 29, 29, 0.08) -1px 0px 0px inset;
 
  min-height: calc(100vh - 150px); 
}

.s-sidebar__nav:hover,
.s-sidebar__nav:focus,
.s-sidebar__trigger:focus + .s-sidebar__nav,
.s-sidebar__trigger:hover + .s-sidebar__nav {
  left: 0;
}

.s-sidebar__nav ul {
  position: absolute;
  top: 4rem;
  left: 0;
  margin: 0 1em;
  padding: 0;
  width: 17em;
}

.s-sidebar__nav ul li {
  width: 100%;
}

.s-sidebar__nav li { 
  list-style: none;
  padding: 0;
}

.s-sidebar__nav-link {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 4em;
}

.s-sidebar__nav-link span {
  position: absolute;
  top: 50%;
  left: 3em;
  transform: translateY(-50%);
  color: #000;
}

.s-sidebar__nav-link > i {
  position: absolute;
  top: 8px;
  left: 20px;
  display: inline-block;
  width: 2em;
  height: 2em;
  font-size: 1.3rem;
  color: rgba(140, 140, 140, 0.4);
}

.s-sidebar__nav-link > i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.s-sidebar__nav a {
  color: rgb(29, 29, 29);
  padding: 12px;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 56px;
  margin-bottom: 8px;
  height: 48px;
  transition: all 0.3s ease 0s;
}


.s-sidebar__nav a svg {
  color: #b5b5b5;
}
.s-sidebar__nav ul li a:hover:not(.active) {
  background-color: #f2f2f3;
}
.s-sidebar__nav a.active {
  color: var(--main-color) !important;
  cursor: pointer;
  background:rgba(var(--main-color-rgb),0.1)
}
.s-sidebar__nav a.active span,
.s-sidebar__nav a.active svg {
  color: var(--main-color)
}

.marginTop {
  margin-top: 2rem;
}

/* Mobile First */
@media (min-width: 42em) {
  .s-layout__content {
    margin-left: 4em;
  }

  /* Sidebar */
  .s-sidebar__trigger {
    width: 4em;
  }

  .s-sidebar__nav {
    width: 4em;
    left: 0;
  }

  .s-sidebar__nav:hover,
  .s-sidebar__nav:focus,
  .s-sidebar__trigger:hover + .s-sidebar__nav,
  .s-sidebar__trigger:focus + .s-sidebar__nav {
    width: 20em;
  }
}

@media (min-width: 68em) {
  .s-layout__content {
    margin-left: 20em;
  }

  /* Sidebar */
  .s-sidebar__trigger {
    display: none;
  }

  .s-sidebar__nav {
    width: 20em;
  }

  .s-sidebar__nav ul {
    top: 1.3em;
  }
}



@media (max-width: 480px) {

  .s-layout__content {
    margin-top: 55px!important;
  }
  .s-layout {
    display: grid !important;
    /* margin-top: 50px !important; */
  }
}