

#nav-button-a.open {
    left: 230px;
}   
#nav-button-a img {
    height: 16px;
    vertical-align: bottom;
}
img {
    border: 0;
}

.content-docs p, .content-docs li, .content-docs dt, .content-docs dd {
    line-height: 1.6;
    margin-top: 0;
}
.content-docs>h1, .content-docs>h2, .content-docs>div {
    clear: both;
}

.content-docs h2 {
    font-size: 19px;
    /* margin-top: 4em; */
    margin-bottom: 0;
    /* border-top: 1px solid #e6eaff; */
    padding-top: 1.2em;
    padding-bottom: 1.2em;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0.2)), to(rgba(255,255,255,0)));
    background-image: linear-gradient(to bottom, rgba(255,255,255,0.2), rgba(255,255,255,0));
}

.content-docs h2 {
    font-size: 19px;
    margin-top: 4rem;
    margin-bottom: 0;
    border-top: 1px solid #e6eaff;
    padding-top: 1.2em;
    padding-bottom: 1.2em;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0.2)), to(rgba(255,255,255,0)));
    background-image: linear-gradient(to bottom, rgba(255,255,255,0.2), rgba(255,255,255,0));
}
.toc-wrapper .toc-link.active {
    color: #2f67f0;
    -webkit-box-shadow: #2f67f0 3px 0px 0px 0px inset;
    box-shadow: #2f67f0 3px 0px 0px 0px inset;
    font-weight: bold;
}
.toc-wrapper .toc-link.active-parent {
    background-color: transparent;
    color: #2f67f0;
    font-weight: bold;
}
.content-docs code {
    background-color: rgba(0,0,0,0.05);
    padding: 3px;
    border-radius: 3px;
    border: none;
}
.content-docs blockquote>p {
    padding: 0 2em;
    color: #eee;
}
#pre-docs {
    background-color: #000000;
    padding: 2em;
    /* width: calc(50% - 0em); */
    -webkit-box-shadow: inset none;
    box-shadow: inset none;
    margin: 0 0 1em 0;
}
#ul-docs {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
pre {
    font-family: monospace;
    white-space: pre;
}
#nav-button-a #sp {
    display: block;
    padding: 6px 6px 6px;
    background-color: rgba(247,250,255,0.7);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(-90deg) translate(-100%, 0);
    transform: rotate(-90deg) translate(-100%, 0);
    border-radius: 0 0 0 5px;
}
#nav-button-a {
padding: 0 1.5em 5em 0;
display: none;
position: fixed;
top: 0;
left: 0;
z-index: 100;
color: #000;
text-decoration: none;
font-weight: bold;
opacity: 0.7;
line-height: 16px;
-webkit-transition: left 0.3s ease-in-out;
transition: left 0.3s ease-in-out;
}

.toc-wrapper {
-webkit-transition: left 0.3s ease-in-out;
transition: left 0.3s ease-in-out;
overflow-y: auto;
overflow-x: hidden;
position: fixed;
z-index: 30;
top: 0;
left: 0;
bottom: 0;
width: 230px;
background-color: #f0f2f5;
font-size: 13px;
font-weight: bold;
}
.toc-wrapper .logo {
display: block;
max-width: calc(100% - 110px);
padding: 20px;
}
img {
border: 0;
}
.toc-wrapper ul, .toc-wrapper li {
list-style: none;
margin: 0;
padding: 0;
line-height: 28px;
}

.toc-wrapper li {
color: #2d3446;
-webkit-transition-property: background;
transition-property: background;
-webkit-transition-timing-function: linear;
transition-timing-function: linear;
-webkit-transition-duration: 200ms;
transition-duration: 200ms;
}





.content-docs pre {
    background-color: #121927;
    padding: 2em;
    width: calc(50% - 0em);
    -webkit-box-shadow: inset none;
    box-shadow: inset none;
    margin: 0 0 1em 0;
}
.content-docs pre, .content-docs blockquote {
    color: #fff;
    margin: 0;
    width: 50%;
    float: right;
    clear: right;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: #2D3446;
    padding: 10px 0;
}
content-docs code, .content-docs pre {
    font-family: Consolas, Menlo, Monaco, "Lucida Console", "Liberation Mono", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Courier New", monospace, serif;
    font-size: 12px;
    line-height: 1.5;
}

.highlight, .highlight .w {
    background-color: #121927;
}
/* .toc-wrapper ul li :active{
    color: #2f67f0;
    -webkit-box-shadow: #2f67f0 3px 0px 0px 0px inset;
    box-shadow: #2f67f0 3px 0px 0px 0px inset;
    font-weight: bold;
} */
.indx {
    color: #333;
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f7faff;
    height: 100%;
    -webkit-text-size-adjust: none;
}
.content-docs h1, .content-docs h2, .content-docs h3, .content-docs h4, .content-docs h5, .content-docs h6, html, .indx{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
}
.highlight .err, .highlight .nl {
    color: #f55151;
}
.content-docs pre {
    background-color: #121927;
    padding: 2em;
    width: calc(50% - 0em);
    -webkit-box-shadow: inset none;
    box-shadow: inset none;
    margin: 0 0 1em 0;
}
.highlight .nl {
    color: #f92672;
}
.highlight .mi {
    color: #ae81ff;
}
.highlight .s2 {
    color: #e6db74;
}
.toc-link, .toc-footer li {
padding: 10px 15px 10px 15px;
display: block;
overflow-x: hidden;
white-space: nowrap;
text-overflow: ellipsis;
text-decoration: none;
color: #2d3446;
-webkit-transition-property: background;
transition-property: background;
-webkit-transition-timing-function: linear;
transition-timing-function: linear;
-webkit-transition-duration: 130ms;
transition-duration: 130ms;
font-weight: 400;
}
#ancor {
background-color: transparent;
}
#ancor:-webkit-any-link {
color: -webkit-link;
cursor: pointer;
text-decoration: none;
}
.toc-wrapper .toc-list-h2 {
display: none;
background-color: #e5e9f0;
font-weight: 500;
}

.toc-wrapper .toc-h2 {
padding-left: 25px;
font-size: 12px;
}

.page-wrapper-docs {
margin-left: 230px;
position: relative;
z-index: 10;
background-color: #f7faff;
min-height: 100%;
padding-bottom: 1px;
}
.page-wrapper-docs .dark-box-docs {
background-color: #1E2433;
position: absolute;
right: 0;
top: 0;
bottom: 0;
}

.content-docs {
-webkit-transform: translateZ(0);
position: relative;
z-index: 30;
overflow: hidden;
}
.content-docs h1:first-child, .content-docs div:first-child+h1 {
border-top-width: 0;
margin-top: 0;
}
#div-docs {
    display: block;
}
.content-docs>ul, .content-docs>ol {
    padding-left: 43px;
}
.content-docs>h1, .content-docs>h2, .content-docs>h3, .content-docs>h4, .content-docs>h5, .content-docs>h6, .content-docs>p, .content-docs>table, .content-docs>ul, .content-docs>ol, .content-docs>aside, .content-docs>dl {
    margin-right: 50%;
    padding: 0 28px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
}
.content-docs p, .content-docs li, .content-docs dt, .content-docs dd {
    line-height: 1.6;
    margin-top: 0;
}

.content-docs pre {
    background-color: #121927;
    padding: 2em;
    width: calc(50% - 0em);
    -webkit-box-shadow: inset none;
    box-shadow: inset none;
    margin: 0 0 1em 0;
}
.content-docs table {
    margin-bottom: 1em;
    overflow: auto;
}
#table-docs {
    border-collapse: collapse;
    border-spacing: 0;
}
#table-docs {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
}
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
.content-docs table tr:last-child {
    border-bottom: 1px solid #e6eaff;
}
#tr-docs {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
#tbody-docs {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}
.content-docs table tr:nth-child(odd)>td {
    background-color: white;
}
.content-docs table td {
    padding: 10px;
}
.content-docs table th, .content-docs table td {
    text-align: left;
    vertical-align: top;
    line-height: 1.6;
}
td, th {
    padding: 0;
}
.content-docs table tr:nth-child(even)>td {
    background-color: white;
}
.content-docs h1 {
font-size: 25px;
padding-top: 0.5em;
padding-bottom: 0.5em;
margin-bottom: 21px;
margin-top: 2em;
border-bottom: 1px solid #edf2fc;
background-color: transparent;
width: 100%;
}
.content-docs h1+h2, .content-docs h1+div+h2 {
margin-top: -21px;
border-top: none;
}
content-docs h2 {
font-size: 19px;
margin-top: 4em;
margin-bottom: 0;
border-top: 1px solid #e6eaff;
padding-top: 1.2em;
padding-bottom: 1.2em;
background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0.2)), to(rgba(255,255,255,0)));
background-image: linear-gradient(to bottom, rgba(255,255,255,0.2), rgba(255,255,255,0));
}
.content-docs p, .content-docs li, .content-docs dt, .content-docs dd {
line-height: 1.6;
margin-top: 0;
}
/* p {
display: block;
margin-block-start: 1em;
margin-block-end: 1em;
margin-inline-start: 0px;
margin-inline-end: 0px;
} */
.content-docs>div.highlight {
    clear: none;
}
.content-docs p, .content-docs li, .content-docs dt, .content-docs dd {
line-height: 1.6;
margin-top: 0;
}
b, strong {
font-weight: bold;
}
strong {
font-weight: bold;
}
.content-docs code {
word-break: break-all;
-webkit-hyphens: auto;
-ms-hyphens: auto;
hyphens: auto;
}
.content-docs code, .content-docs pre {
font-family: Consolas, Menlo, Monaco, "Lucida Console", "Liberation Mono", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Courier New", monospace, serif;
font-size: 12px;
line-height: 1.5;
}
code, kbd, pre, samp {
font-family: monospace, monospace;
font-size: 1em;
}
.content-docs pre {
    background-color: #121927;
    padding: 2em;
    width: calc(50% - 0em);
    -webkit-box-shadow: inset none;
    box-shadow: inset none;
    margin: 0 0 1px 0;
}

  .content-docs h1, .content-docs h2, .content-docs h3, .content-docs h4, .content-docs h5, .content-docs h6 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    /* font-size: 14px; */
  }

.content-docs p, .content-docs li, .content-docs dt, .content-docs dd {
line-height: 1.6;
margin-top: 0;
}
.content-docs>ul, .content-docs>ol {
padding-left: 43px;
}
.content-docs p, .content-docs li, .content-docs dt, .content-docs dd {
line-height: 1.6;
margin-top: 0;
}

.content-docs pre, .content-docs blockquote {
color: #fff;
margin: 0;
width: 50%;
float: right;
clear: right;
-webkit-box-sizing: border-box;
box-sizing: border-box;
background: #2D3446;
padding: 10px 0;
}
blockquote {
display: block;
margin-block-start: 1em;
margin-block-end: 1em;
margin-inline-start: 40px;
margin-inline-end: 40px;
}

.content-docs pre {
background-color: #121927;
padding: 2em;
width: calc(50% - 0em);
-webkit-box-shadow: inset none;
box-shadow: inset none;
margin: 0 0 1em 0;
}
content-docs code, .content-docs pre {
font-family: Consolas, Menlo, Monaco, "Lucida Console", "Liberation Mono", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Courier New", monospace, serif;
font-size: 12px;
line-height: 1.5;
}
.highlight, .highlight .w {
background-color: #121927;
}
.highlight {
color: #ffffff;
}
code, kbd, pre, samp {
font-family: monospace, monospace;
font-size: 1em;
}
pre {
overflow: auto;
}

.content-docs code {
word-break: break-all;
-webkit-hyphens: auto;
-ms-hyphens: auto;
hyphens: auto;
}
.content-docs code, .content-docs pre {
font-family: Consolas, Menlo, Monaco, "Lucida Console", "Liberation Mono", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Courier New", monospace, serif;
font-size: 12px;
line-height: 1.5;
}
.content-docs h3, .content-docs h4, .content-docs h5, .content-docs h6 {
font-size: 15px;
margin-top: 2.5em;
margin-bottom: 0.8em;
}
.content-docs table {
margin-bottom: 1em;
overflow: auto;
}
thead {
display: table-header-group;
vertical-align: middle;
border-color: inherit;
}
#table-docs {
border-collapse: collapse;
border-spacing: 0;
}
#table-docs {
border-collapse: separate;
text-indent: initial;
border-spacing: 2px;
}
.content-docs table tr:last-child {
border-bottom: 1px solid #e6eaff;
}

.content-docs>div.highlight {
clear: none;
}

.content-docs pre {
background-color: #121927;
padding: 2em;
width: calc(50% - 0em);
-webkit-box-shadow: inset none;
box-shadow: inset none;
margin: 0 0 1em 0;
}

blockquote {
display: block;
margin-block-start: 1em;
margin-block-end: 1em;
margin-inline-start: 40px;
margin-inline-end: 40px;
}
.content-docs>ul, .content-docs>ol {
    padding-left: 43px;
}
.highlight .o {
    color: #f92672;
}
.highlight .nt {
    color: #f92672;
}
.highlight .s1 {
    color: #e6db74;
}

@media (max-width: 930px){
#nav-button-a {
    display: block;
}
.toc-wrapper {
    left: -230px;
}
.page-wrapper-docs {
    margin-left: 0;
}
.page-wrapper-docs .dark-box-docs {
    width: 0 !important;
}
}
@media (max-width: 700px){
.content-docs>h1, .content-docs>h2, .content-docs>h3, .content-docs>h4, .content-docs>h5, .content-docs>h6, .content-docs>p, .content-docs>table, .content-docs>ul, .content-docs>ol, .content-docs>aside, .content-docs>dl {
    margin-right: 0;
}
.page-wrapper-docs .dark-box-docs {
    width: 0 !important;
}
.dark-box-docs {
    display: none;
}
.content-docs>h1, .content-docs>h2, .content-docs>h3, .content-docs>h4, .content-docs>h5, .content-docs>h6, .content-docs>p, .content-docs>table, .content-docs>ul, .content-docs>ol, .content-docs>aside, .content-docs>dl {
    margin-right: 0;
}
.content-docs pre, .content-docs blockquote {
    width: auto;
    float: none;
}
.content-docs pre, .content-docs blockquote {
    width: auto;
    float: none;
}
.content-docs pre {
    margin: 1em 0;
}
}